import React from 'react'
import { withPreview } from 'gatsby-source-prismic'

import WysiwygPage from '../components/Templates/WysiwygPage'
import { graphql } from 'gatsby'
import { extract } from '../utils/graphql'
// import mergePreviewData from '../utils/mergePreviewData'

const ContentPage = ({ data, pageContext }) => {
  const { prismicEconomicInnovationArticle } = data
  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(prismicEconomicInnovationArticle),
    mantelData: extract.mantel(prismicEconomicInnovationArticle, {
      isThick: true,
    }),
    content: prismicEconomicInnovationArticle.data.content.html,
  }
  return <WysiwygPage {...hydratedData} />
}

export default withPreview(ContentPage)

export const pageQuery = graphql`
  query InnovationArticleBySlug($uid: String!, $locale: String!) {
    prismicEconomicInnovationArticle(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        content {
          html
        }
      }
    }
  }
`
